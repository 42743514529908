import "./interproject"
import Loader from "@/components/loader"
import Cookies from "@/helpers/cookies"
import Emarsys from "@/helpers/emarsys"
import { setupGa4 } from "@/helpers/ga4_helper"

declare global {
  interface Window {
    clearExperimentCookie: () => void
    setExperimentCookie: (newCookie: string) => void
  }
}

const initExperiments = (): void => {
  const EXPERIMENT_COOKIE = "experiments"

  window.setExperimentCookie = (newCookie: string): void => {
    Cookies.set(EXPERIMENT_COOKIE, newCookie, { expires: 9999 })
  }

  window.clearExperimentCookie = (): void => {
    Cookies.remove(EXPERIMENT_COOKIE)
  }
}

const removeSpinner = (): void => {
  window.addEventListener("pagehide", Loader.removeAll)
}

const setTurboEval = (): void => {
  // Allow some GTM scripts to re-run by ignoring `data-turbo-eval="true"`
  const scriptSelector = "script:not([data-turbo-eval='true'])"

  document.body.querySelectorAll(scriptSelector).forEach((script) => {
    script.setAttribute("data-turbo-eval", "false")
  })
}

const scriptMutationObserver = (callback: () => void): void => {
  new window.MutationObserver((mutations): void => {
    mutations.forEach((mutation) => {
      if ([...mutation.addedNodes].some((node) => node.nodeName === "SCRIPT")) {
        callback()
      }
    })
  }).observe(document.body, { subtree: true, childList: true })
}

// Don't call Emarsys for old vouchers, since the Elm app does that when it loads
const NEW_VOUCHERS = !document.getElementById("js-voucher-bookings")
if (NEW_VOUCHERS) {
  // This gets called on every page to ensure the cart events get sent to emarsys.
  Emarsys(window.emarsysData)
}

initExperiments()
setupGa4()
removeSpinner()
setTurboEval()
scriptMutationObserver(setTurboEval)
